import * as React from 'react'
import { useRouter } from 'next/router'
import { FormLayout, InputBox, SEO } from '../../'
import { trackQuoteInput } from '@/clients/segment'
import { Context } from '../../../context'
import { useLeadData } from '@/hooks'
import {
  getCategoryFromForm,
  getLabelFromForm,
  getStateName,
  ls,
  paramsToQueryString,
  parsePath,
  pathForNextStep,
  progress,
} from '@/utils'
import getSEOInformation from '@/utils/form-helpers/get-seo-information'

// Please reference the old repo to determine which props are needed
// for each `FormPanel`, as they do differ
interface Props {
  openInNewTab?: boolean
  onHandleSubmit?: () => void
}

export function ZipCodePanel(props: Props) {
  /*********************************************************************
   * - get initial value and state setter from lead data
   * - track input value
   * - track submission state for the `validate` function
   * - manage loading animation for button
   */
  const [zipcode, setZipcode] = useLeadData('zipcode')
  const [inputVal, setInputVal] = React.useState(zipcode || '')
  const [userDidSubmit, setUserDidSubmit] = React.useState(false)
  const [showBtnLoader, setShowBtnLoader] = React.useState(false)

  /*********************************************************************
   * Prefetch the next page of the form, depending on the form.
   * Note that the first argument passed to the `progress` function should be
   * the current path, e.g. `drivers/license`. The reason it is an empty string
   * here is because the zip code page is the `index` page of the `[form]`.
   */
  const router = useRouter()
  const form = parsePath(router.query)
  const category = getCategoryFromForm(form)
  const { step, denominator } = progress('', form)

  React.useEffect(() => {
    router.prefetch(pathForNextStep(step, form).pathname)
  }, [])

  /*********************************************************************
   * NOTE: this code block is unique to the zip code panel
   * Get client's location to display a geo-specific question label
   */
  const { clientLocation } = React.useContext(Context)
  const [labelState, setLabelState] = React.useState({
    stateIsLoading: true,
    label: ' ',
  })

  React.useEffect(() => {
    const stateName = getStateName(clientLocation.state) || 'your region';

    const state = {
      stateIsLoading: clientLocation.status !== 'done',
      label: clientLocation.status === 'done' && !clientLocation.state
        ? "Let's get started!"
        : clientLocation.status === 'done'
        ? `Compare ${getLabelFromForm(form)} ${
            category === 'auto-insurance' ? 'Rates' : 'Plans'
          } in ${stateName}`
        : ' '
    }
    setLabelState({ ...state })
  }, [clientLocation.status, router.asPath])

  /*******************************************************************************
   * Default submit handler - user continues to RQ Form
   */
  const handleSubmit = () => {
    setUserDidSubmit(true)
    const formIsValid = validate({ key: 'zipcode', force: true })

    if (formIsValid) {
      setShowBtnLoader(true)
      const { openInNewTab, onHandleSubmit } = props
      const nextStep = pathForNextStep(step, form, {
        zipcode: inputVal,
      })
      trackQuoteInput({
        step,
        name: 'zip', // please keep the name the same as it was before
        value: inputVal as string,
      })
      setZipcode(inputVal as string)
      ls.setItem('previousStep', step)

      // `openInNewTab` is used when on /savings/generic --
      // this if/else block is unique to the zip code panel
      if (openInNewTab) {
        const queryParams = paramsToQueryString(nextStep.query)
        const baseUrl = nextStep.pathname
        window.open(`${baseUrl}?${queryParams}`, '_blank')
        ls.setItem('goToFormIndex', true)
      } else {
        // this is what should be used on the other `FormPanel` components
        router.push(nextStep).then(() => window.scrollTo(0, 0))
      }

      // unique to zip code panel
      onHandleSubmit && onHandleSubmit()
    } else return
  }

  /*******************************************************************************
   * Use this function to validate the input -- the logic should be adapted
   * for each FormPanel, but the structure of the function should stay the
   * same throughout. Pull logic from old repo.
   */
  const validate = ({
    key,
    force,
  }: {
    key: string
    force?: boolean
  }): boolean => {
    if (!userDidSubmit && !force) return true
    if (key === 'zipcode') return /(^\d{5}$)/.test(inputVal as string)
    else return false
  }

  const renderInput = (
    <div className="max-w-84 mx-auto">
      <InputBox
        error={!validate({ key: 'zipcode' })}
        errorMsg="Please enter a valid zip code"
        id="get-quotes--zip"
        inputMode="numeric"
        labelId="form-label"
        maxLength={5}
        name="zip"
        onChange={setInputVal}
        pattern="[0-9]*"
        placeholder="Enter Your Zip Code"
        type="tel"
        value={inputVal}
      />
    </div>
  )

  const commonProps = {
    progress: { step, denominator },
    questionLabel: labelState.label,
    showBtnLoader,
    form,
  }
  const seoInformation = getSEOInformation(category, form)
  const pagePath = router.query.form as string

  return (
    <>
      {/* The SEO component is only relevant for the Zip Code page */}
      <SEO
        title={seoInformation.title}
        description={seoInformation.description}
      />
      <FormExperience
        submitHandler={handleSubmit}
        {...commonProps}
        key={pagePath}
      >
        {renderInput}
      </FormExperience>
    </>
  )
}

interface FormExperienceProps {
  submitHandler: (() => void) | undefined
  children: React.ReactChild
  progress: {
    step: number
    denominator: number
  }
  questionLabel: string
  showBtnLoader: boolean
  form: string
}

const FormExperience = (props: FormExperienceProps) => {
  const { submitHandler, children, ...rest } = props
  return (
    <FormLayout onSubmit={submitHandler} {...rest}>
      {children}
    </FormLayout>
  )
}
