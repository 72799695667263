export const calculateDate = {
  dob: (eligibilityDate: { day: string; month: string; year: string }) => {
    if (!eligibilityDate.day || !eligibilityDate.month || !eligibilityDate.year)
      return {
        day: '',
        month: '',
        year: '',
      }

    const dateEligible = new Date(
      +eligibilityDate.year,
      +eligibilityDate.month - 1,
      +eligibilityDate.day,
    )

    const dob = new Date(
      dateEligible.setFullYear(dateEligible.getFullYear() - 65),
    )

    return {
      day: dob.getDate().toString(),
      month: (dob.getMonth() + 1).toString().padStart(2, '0'),
      year: dob.getFullYear().toString(),
    }
  },
  eligibility: (dob: {
    day: string | null
    month: string | null
    year: string | null
  }) => {
    if (!dob.day || !dob.month || !dob.year)
      return {
        day: '',
        month: '',
        year: '',
      }
    const dobDate = new Date(+dob.year, +dob.month - 1, +dob.day)

    const eligibilityDate = new Date(dobDate)
    eligibilityDate.setFullYear(dobDate.getFullYear() + 65)

    return {
      day: eligibilityDate.getDate().toString(),
      month: (eligibilityDate.getMonth() + 1).toString().padStart(2, '0'),
      year: eligibilityDate.getFullYear().toString(),
    }
  },
}
