import * as Sentry from '@sentry/nextjs'
import crypto from 'crypto'
import { getBirthDate } from '../'
import { useAllLeadData, UserContext } from '../../hooks'
import { LeadData } from '../../hooks/use-lead-data/types'
import { mapKeys, mapValues, omit, pipe, propOr } from 'lodash/fp'
import { Segment } from '../../../types'
import { identify } from '../../clients/segment'

const delay = (ms = 500) => new Promise((res) => setTimeout(res, ms))

export const segment = {
  getAnonymousId: async function (retry = 9): Promise<string | null> {
    try {
      await delay()
      if (typeof window.analytics === 'undefined') {
        throw new Error('Analytics not initialized')
      }
      return window.analytics.user().anonymousId()
    } catch (error) {
      if (retry > 0) {
        return await segment.getAnonymousId(retry - 1)
      } else {
        Sentry.captureException('Error getting Segment ID')
        return null
      }
    }
  },
}

export function postIdentity(userContext: UserContext) {
  const leadData = useAllLeadData()
  const traits = transformTraits(leadData, userContext)
  const email = leadData.contactEmail || null
  identify(email, traits)
}

export function hashString(str: string) {
  return crypto.createHash('md5').update(str).digest('hex')
}

export function transformTraits(
  leadData: Partial<LeadData.All>,
  userContext: UserContext,
) {
  const keyMapping: { [key: string]: string } = {
    contactFirstName: 'firstName',
    contactLastName: 'lastName',
    creditScore: 'providedCreditScore',
    driverSr22: 'driverSR22',
    incidentsAccident: 'incidentAccident',
    incidentsClaim: 'incidentClaim',
    incidentsDui: 'incidentDUI',
    incidentsSuspension: 'incidentSuspension',
    incidentsTickets: 'incidentTickets',
    secondVehicle: 'vehicleAddSecond',
    zipcode: 'zip',
  }
  const traits = pipe(
    mapKeys((key: string) => keyMapping[key] || key),
    mapValues((val: any) => {
      if (val === '0') return false
      if (val === '1') return true
      return val
    }),
    // @ts-ignore
    cleanUpGatedQuestions.bind(this, 'vehicleAddSecond', 'vehicleYear2', [
      'vehicleMake2',
      'vehicleModel2',
      'vehicleYear2',
    ]),
    cleanUpGatedQuestions.bind(
      // @ts-ignore
      this,
      'autoInsuranceCurrent',
      'autoInsuranceProvider',
      ['autoInsuranceProvider'],
    ),
    cleanUpGatedQuestions.bind(
      // @ts-ignore
      this,
      'healthInsuranceCurrent',
      'healthInsuranceProvider',
      ['healthInsuranceProvider'],
    ),
    cleanUpGatedQuestions.bind(
      // @ts-ignore
      this,
      'healthInsuranceSpouse',
      'spouse',
      ['spouse'],
    ),
    cleanUpGatedQuestions.bind(
      // @ts-ignore
      this,
      'healthInsuranceDependents',
      'dependents',
      ['dependents'],
    ),
  )({
    version: '2.1',
    email: leadData.contactEmail,
    name: `${leadData.contactFirstName} ${leadData.contactLastName}`,
    phone: propOr('', 'contactPhoneNumber', leadData).replace(/\D/g, ''),
    leadId: userContext.leadId,
    contactCounty: leadData.contactCounty || '',
    contactZipSuffix: leadData.contactZipSuffix || '',
    ...leadData,
  })

  const fallbackDOB = getBirthDate({ value: 65 })

  return {
    birthDay: fallbackDOB.birthDay,
    birthMonth: fallbackDOB.birthMonth,
    birthYear: fallbackDOB.birthYear,
    ...(traits as Segment.UserTraits),
    contactAptSteOther: leadData.contactAptSteOther || '',
    healthInsuranceDependents: traits.healthInsuranceDependents
      ? leadData.healthInsuranceDependents
      : '0',
  }
}
function cleanUpGatedQuestions(
  gateQ: string,
  gatedQ: string,
  propsToOmit: string[],
  obj: any,
) {
  if (!obj[gateQ] && Object.keys(obj).includes(gatedQ)) {
    return omit(propsToOmit, obj)
  } else return obj
}
